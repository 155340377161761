export const codeListPagesMap: any = {
  waiting: '/funnel/outages-waiting',
  invalidCombination: '/funnel/invalid-combination',
  puk: '/funnel/puk',
  finalpage: '/funnel',
  block: '/funnel/block',
  login: '/funnel/login',
  information: '/funnel/information',
  duplicity: '/funnel/duplicity',
  plannedOutage: '/funnel/planned-outage',
  informationChat: '/funnel/information-chat',
  informationWithTwoActions: '/funnel/information-two-actions',
  technicianVisitOffer: '/funnel/technician-visit-offer',
  brokenDevice: '/funnel/broken-modem',
  informationSatiSelfcare: '/funnel/sati-self-care',
  solution: '/funnel/modem-involved',
  technicianVisitRequired: '/funnel/technician-visit-date',
  main: '/funnel/confirmation',
  confirmation: '/funnel/confirmation',
  configurationError: '/funnel/configuration-error',
  deviceConfigRequired: '/funnel/device-config-required',
  selectSolution: '/funnel/select-solution',
  address: '/funnel/address',
  serviceUnavailable: '/funnel/service-unavailable',
  termNotFound: '/funnel/technician-visit-no-date',
  selectTerm: '/funnel/technician-visit',
  submitTerm: '/funnel/technical-visit-required-confirm',
  setTerm: '/funnel/set-term',
  solutionWithoutInteraction: '/funnel/solution-without',
  unavailable: '/funnel/unavailable',
  invalidNumber: '/funnel/invalid-number',
  informationChatSelfcare: '/funnel/information-chat-selfcare',
  notSupportedModem: '/funnel/not-supported-modem',
  selectHdmFunction: '/funnel/select-hdm-function',
  viewWifi: '/funnel/view-wifi',
  lanPorts: '/funnel/setup-lan',
  firmwareUpdate: '/funnel/update-firmware',
  backup: '/funnel/backup',
  restart: '/funnel/restart',
  reset: '/funnel/reset',
  configWifi: '/funnel/config-wifi',
  cannotUpdateFirmware: '/funnel/cannot-update-firmware',
  recovery: '/funnel/recovery',
  troubleList: '/funnel/trouble-list',
  troubleDetail: '/funnel/trouble-list/',
  saved: '/funnel/extended-time',
  selectNumber: '/funnel/select-number',
  selectService: '/funnel/select-number-two',
  selectTrouble: '/funnel/select-trouble',
  o2tvCannotLogin: '/funnel/o2tv-cannot-login',
  outagesSelectService: '/funnel/outages-select-service',
  outagesMap: '/funnel/outages-map',
  outagesSelectNumber: '/funnel/outages-select-number',
  outagesNotFound: '/funnel/outages-not-found',
  noOutage: '/funnel/no-outage',
  outage: '/funnel/outage',
  timeout: '/funnel/information-chat',
  badPayer: '/funnel/account-issue',
  noDevice: '/funnel/no-device',
  selectDevice: '/funnel/select-device',
  error: '/funnel/error',
  notSupportedCustomer: '/funnel/not-supported-customer',
  informationSelfcare: '/funnel/information-selfcare',
  forbidden: '/funnel'
};
