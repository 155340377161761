import { O2Indent, O2Button } from 'o2-theme-react';
import React, { useState } from 'react';

import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import useAnalytics from '../../../src/hooks/useAnalytics';
import HTMLBody from '../common/HTMLBody';
//import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const InformationSatiSelfcare: React.FC = props => {
  const namePage = 'informationSatiSelfcare';
  useAnalytics(namePage);
  //useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const sharedObjects = useSelector<any, any>(state => state.sharedObjects);

  const [ ttId ] = useState(
    get(page, 'ttsParams.ttId', null)
  );

  const [ destinationId ] = useState(get(page, 'ttsParams.destinationId', null));

  const openChat = () => {
    (window as any).postMessage({
      content: {
        reason: 'ManuallyCalled',
      },
      operation: 'RequestWidgetOpenWindow',
    });
  };

  const redirect = () => {
    const cuRefNo = sharedObjects?.sharedObjectSession?.customer.cuRefNo;

    window.location.href = `https://spokojenost.o2.cz/poruchy/${ttId}/${cuRefNo}`;
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.informationSatiSelfcare.title' />
        )
      }
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
        {!destinationId && <Langtext text='funnel.informationSatiSelfcare.text' />}
      </O2Indent>
      <O2Indent>
        <O2Button
          indentRight
          color='positive'
          onClick={() => redirect()}
        >
          <Langtext text='funnel.informationSatiSelfcare.button1' />
        </O2Button>
        <O2Button
          onClick={() => openChat()}
          style={{
            backgroundColor: '#990000',
            color: 'white'
        }}
        >
          <Langtext text='funnel.informationSatiSelfcare.button2' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InformationSatiSelfcare;
