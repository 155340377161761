import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import ConfigureDevice from './deeplinks/ConfigureDevice';
import CreateTicket from './deeplinks/CreateTicket';
import History from './deeplinks/History';
import Address from './pages/Address';
import Backup from './pages/Backup';
import Badpay from './pages/Badpay';
import Block from './pages/Block';
import BrokenModem from './pages/BrokenModem';
import CannotUpdateFw from './pages/CannotUpdateFw';
import ConfigurationError from './pages/ConfigurationError';
import DeviceConfigRequired from './pages/DeviceConfigRequired';
import Duplicity from './pages/Duplicity';
import Error from './pages/Error';
import ExtendedTime from './pages/ExtendedTime';
import InformationSelfcare from './pages/InformationSelfcare';
import Information from './pages/Information';
import InformationChat from './pages/InformationChat';
import InformationWithTwoActions from './pages/InformationWithTwoActions';
import InformationChatSelfcare from './pages/InformationChatSelfcare';
import InvalidCombination from './pages/InvalidCombination';
import InvalidNumber from './pages/InvalidNumber';
import ModemInvolved from './pages/ModemInvolved';
import NoDevice from './pages/NoDevice';
import NoOutage from './pages/NoOutage';
import NotSupportedCustomer from './pages/NotSupportedCustomer';
import O2tvCannotLogin from './pages/O2tvCannotLogin';
import OutagesNotFound from './pages/OutagesNotFound';
import OutagesSelectNumber from './pages/OutagesSelectNumber';
// import OutagesSelectService from './pages/OutagesSelectService';
import NonAuthorized from './pages/NonAuthorized';
import OutagesWaiting from './pages/OutagesWaiting';
import PageSwitcher from './pages/PageSwitcher';
import PUK from './pages/Puk';
import Recovery from './pages/Recovery';
import Reset from './pages/Reset';
import Restart from './pages/Restart';
import SelectDevice from './pages/SelectDevice';
import SelectHdmFunction from './pages/SelectHdmFunction';
import Login from './pages/Login';
import SelectNumber from './pages/SelectNumber';
import SelectNumberTwo from './pages/SelectNumber_2';
import SelectSolution from './pages/SelectSolution';
import SelectTrouble from './pages/SelectTrouble';
import ServiceUnavailable from './pages/ServiceUnavailable';
import SetTerm from './pages/SetTerm';
import SetupLan from './pages/SetupLan';
import SolutionWithoutInteraction from './pages/SolutionWithoutInteraction';
import TechnicianVisit from './pages/TechnicianVisit';
import TechnicalVisitConfirm from './pages/TechnicianVisitConfirm';
import TechnicianVisitDate from './pages/TechnicianVisitDate';
import TechnicianVisitNoDate from './pages/TechnicianVisitNoDate';
import TechnicianVisitOffer from './pages/TechnicianVisitOffer';
import TroubleList from './pages/TroubleList';
import TroubleListDetails from './pages/TroubleListDetails';
import Unavailable from './pages/Unavailable';
import UpdateFw from './pages/UpdateFw';
import ViewWifi from './pages/ViewWifi';
import ConfigWifi from './pages/ConfigWifi';
import CreateTTWithMap from './deeplinks/CreateTTWithMap';
import OpenTicket from './deeplinks/OpenTicket';
import NotSupportedModem from './pages/NotSupportedModem';
import InternalNoContext from './pages/InternalNoContext';
import Confirmation from './pages/Confirmation';
import FinalPage from './pages/FinalPage';
import PlannedOutage from './pages/PlannedOutage';
import InformationSatiSelfcare from './pages/InformationSatiSelfcare';

const Routes = ({ user, config }: any) => {
  if (user.userInfo.internal && user.userInfo.roles && !user.userInfo.roles.includes('OP_FUNNEL_CC')) {
    return (
      <Switch>
        <Route path='/funnel/non-authorized' component={NonAuthorized} />
        <Redirect to='/funnel/non-authorized' />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path='/'>
          <Redirect to='/funnel/' />
        </Route>
        <Route exact path='/funnel' component={FinalPage} />
        <Route exact path='/funnel?jwtChecked=true' component={FinalPage} />
        <Route exact path='/funnel/?jwtChecked=true' component={FinalPage} />
        {
            //DEEPLINKS START
          }
          <Route
            // note: params handled internaly in component!
            // serviceId, troubleId, contactPhone, token, worklog, x, y
            path='/funnel/deeplink/createticket'
            component={CreateTicket}
          />
          <Route
            // note: params handled internaly in component!
            // ttid, token
            path='/funnel/deeplink/history'
            component={History} 
          />
          <Route
            // note: params handled internaly in component!
            // serviceId, token
            path='/funnel/deeplink/configuredevice'
            component={ConfigureDevice}
          />
          <Route
            // note: params handled internaly in component!
            // x, y, token
            path='/funnel/deeplink/createTTwithMap'
            component={CreateTTWithMap}
          />
          <Route
            // note: params handled internaly in component!
            // ttId, buttonType, token
            path='/funnel/deeplink/openticket'
            component={OpenTicket}
          />
          <Route exact path='/funnel/deeplink/massoutages'>
            <Redirect to='/funnel' />
          </Route>
          {
            //DEEPLINKS END
          }
        <Route path='/funnel/select-number' component={SelectNumber} />
        <Route path='/funnel/select-number-two' component={SelectNumberTwo} />
        <Route path='/funnel/confirmation' component={Confirmation} />
        <Route exact path='/funnel/select-trouble' component={SelectTrouble} />

        <Route path='/funnel/page-switcher' component={PageSwitcher} />
        <Route path='/funnel/login' component={Login} />
        <Route path='/funnel/no-context' component={InternalNoContext} />
        <Route
          path='/funnel/outages-select-service'
          // component={OutagesSelectService}
        >
          <Redirect to='/funnel' />
        </Route>
        <Route path='/funnel/o2tv-cannot-login' component={O2tvCannotLogin} />
        <Route path='/funnel/outages-waiting' component={OutagesWaiting} />
        <Route
          path='/funnel/invalid-combination'
          component={InvalidCombination}
        />
        <Route path='/funnel/invalid-number' component={InvalidNumber} />
        <Route path='/funnel/planned-outage' component={PlannedOutage} />
        <Route
          path='/funnel/device-config-required'
          component={DeviceConfigRequired}
        />
        <Route path='/funnel/no-outage' component={NoOutage} />

        <Route exact path='/funnel/trouble-list' component={TroubleList} />
        <Route path='/funnel/trouble-list/:id' component={TroubleListDetails} />
        <Route exact path='/funnel/select-device' component={SelectDevice} />
        <Route
          path='/funnel/not-supported-modem'
          /*component={() => {
            window.location.href = config['link.notSupportedModem'];
            return null;
          }}*/
          component={NotSupportedModem}
        />
        <Route
          path='/funnel/select-hdm-function'
          component={SelectHdmFunction}
        />
        <Route
          path='/funnel/information-chat-selfcare'
          component={InformationChatSelfcare}
        />
        <Route path='/funnel/information-selfcare' component={InformationSelfcare} />
        <Route path='/funnel/information-chat' component={InformationChat} />
        <Route path='/funnel/information-two-actions' component={InformationWithTwoActions} />
        <Route path='/funnel/modem-involved' component={ModemInvolved} />
        <Route
          path='/funnel/solution-without'
          component={SolutionWithoutInteraction}
        />
        <Route path='/funnel/extended-time' component={ExtendedTime} />
        <Route path='/funnel/broken-modem' component={BrokenModem} />
        <Route path='/funnel/sati-self-care' component={InformationSatiSelfcare} />
        <Route path='/funnel/duplicity' component={Duplicity} />
        <Route path='/funnel/select-solution' component={SelectSolution} />
        <Route path='/funnel/address' component={Address} />
        <Route path='/funnel/account-issue' component={Badpay} />
        <Route
          path='/funnel/technical-visit-required-confirm'
          component={TechnicalVisitConfirm}
        />
        <Route path='/funnel/no-device' component={NoDevice} />
        <Route path='/funnel/backup' component={Backup} />
        <Route path='/funnel/recovery' component={Recovery} />
        <Route path='/funnel/restart' component={Restart} />
        <Route path='/funnel/view-wifi' component={ViewWifi} />
        <Route path='/funnel/config-wifi' component={ConfigWifi} />
        <Route path='/funnel/puk' component={PUK} />
        <Route
          path='/funnel/configuration-error'
          component={ConfigurationError}
        />
        <Route
          path='/funnel/technician-visit-date'
          component={TechnicianVisitDate}
        />
        <Route
          path='/funnel/technician-visit-no-date'
          component={TechnicianVisitNoDate}
        />
        <Route path='/funnel/update-firmware' component={UpdateFw} />
        <Route
          path='/funnel/cannot-update-firmware'
          component={CannotUpdateFw}
        />
        <Route path='/funnel/reset' component={Reset} />
        <Route path='/funnel/technician-visit' component={TechnicianVisit} />
        <Route path='/funnel/setup-lan' component={SetupLan} />
        <Route exact path='/funnel/set-term' component={SetTerm} />
        <Route exact path='/funnel/unavailable' component={Unavailable} />
        <Route path='/funnel/non-authorized' component={NonAuthorized} />
        <Route path='/funnel/block' component={Block} />
        <Route
          path='/funnel/technician-visit-offer'
          component={TechnicianVisitOffer}
        />
        <Route
          path='/funnel/service-unavailable'
          component={ServiceUnavailable}
        />
        <Route
          path='/funnel/outages-select-number'
          component={OutagesSelectNumber}
        />
        <Route path='/funnel/outages-not-found' component={OutagesNotFound} />
        <Route path='/funnel/information' component={Information} />
        <Route
          path='/funnel/not-supported-customer'
          component={NotSupportedCustomer}
        />
        <Route path='/funnel/speedtest'>
          <Redirect to='/funnel' />
        </Route>
        <Route path='/funnel/error' component={Error} />
        {/* redirect to final page when nonsense url */}
        <Route path="/funnel/*" component={FinalPage} />
      </Switch>
    );
  }
};

export default Routes;
