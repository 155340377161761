import React from 'react';
import LinkMenu from '../../../src/components/common/LinkMenu';

const links = [
  { title: '99.1.1 Dashboard', link: '/funnel' },
  { title: '99.2.1 Přihlášení rozcestník', link: '/funnel/not-logged' },
  { title: '99.2.2 Pokračovat bez přihlášení', link: '/funnel/select-number' },
  { title: '99.3 Výběr čísla', link: '/funnel/select-number-two' },
  {
    title: '99.3.1.5 Informace o výpadcích',
    link: '/funnel/outages-select-service',
  },

  { title: '99.3.2.1 Hlášení poruchy', link: '/funnel/select-trouble' },
  {
    title: '99.3.2.1.1 O2 TV problém',
    link: '/funnel/o2tv-cannot-login',
  },
  { title: '99.3.4 Ověřování', link: '/funnel/outages-waiting' },
  { title: '99.5.1 Neplatná kombinace', link: '/funnel/invalid-combination' },
  { title: '99.5.2 Výpadek', link: '/funnel/planned-outage' },
  {
    title: '99.5.3 Problém s účtem',
    link: '/funnel/information-chat-selfcare',
  },

  { title: '99.5.4 FUP', link: '/funnel/information-selfcare' },
  { title: '99.5.7 Jiná chyba', link: '/funnel/information-chat' },
  { title: '99.6.1 Zapojení modemu', link: '/funnel/modem-involved' },
  { title: '99.6.1.2 Prodloužení času', link: '/funnel/extended-time' },
  { title: 'SatiSelfCare', link: '/funnel/sati-self-care' },
  { title: '99.6.3 Vadný modem', link: '/funnel/broken-modem' },
  { title: '99.6.3.1 Adresa', link: '/funnel/address' },
  {
    title: '99.6.4 Špatně nastavený modem',
    link: '/funnel/device-config-required',
  },
  { title: '99.6.6 Zaznamenaný výpadek', link: '/funnel/outage' },
  { title: '99.6.7 Pred chat', link: '/funnel/before-chat' },
  {
    title: '99.6.8.1.1.1 Datum návštěvy technika',
    link: '/funnel/technical-visit-required-confirm',
  },
  { title: '99.8.1 Historie poruch', link: '/funnel/trouble-list' },
  { title: '99.8.1.1 Detail poruchy', link: '/funnel/trouble-list/1' },
  { title: '99.9.1.1 Více DSL přípojek', link: '/funnel/select-device' },
  { title: '99.9.1.2 Nemáte žádné pevné služby', link: '/funnel/no-device' },
  { title: '99.10.1 Nastaveni modemu', link: '/funnel/select-hdm-function' },
  { title: '99.11.1 Nastaveni wifi', link: '/funnel/view-wifi' },
  { title: '99.11.4 Zaloha nastaveni', link: '/funnel/backup' },
  { title: '99.11.5 Záloha nastavení', link: '/funnel/recovery' },
  { title: '99.11.6 Restart modemu', link: '/funnel/restart' },
  { title: '99.12.2 Nastavení DSL modemu', link: '/funnel/modem-auto-setup' },
  {
    title: '99.13 Reseni nejcastejsich problemu mobilni volani',
    link: '/funnel/mobile-calls-issues',
  },
  { title: '99.6.5 Problém u nás', link: '/funnel/problem-here' },
  {
    title: '99.6.8 Datum návštěvy technika',
    link: '/funnel/technician-visit-date',
  },
  {
    title: '99.6.8.1 datum-navstevy-technika-termin-nenalezen',
    link: '/funnel/technician-visit-no-date',
  },
  { title: '99.11.3 aktualizace-fw', link: '/funnel/update-firmware' },
  { title: '99.11.7 reset', link: '/funnel/reset' },
  { title: '99.13.1 mam-potize-se-signalem', link: '/funnel/signals-problem' },
  {
    title: '99.6.8.1.1 datum-navstevy-technika',
    link: '/funnel/technician-visit',
  },
  { title: '99.11.2 nastaveni-lan', link: '/funnel/setup-lan' },

  {
    title: '99.6.8.1 Datum návštěvy - výběr',
    link: '/funnel/set-term',
  },
  { title: 'PUK', link: '/funnel/puk' },
  { title: 'Information', link: '/funnel/information' },
];
const PageSwitcher: React.FC = () => <LinkMenu data={links} />;

export default PageSwitcher;
